<template>
    <div>
        <div class="section mt-2 text-center">
            <img class="auth-logo" src="../../assets/img/metriclogo_color.png" alt="metrics logo" style="">
            <h3 class="mt-3">Recover Your Assets</h3>
            <!--            <h4>Fill the form to log in</h4>-->
        </div>
        <div class="section mb-5 p-2">

            <form role="form">
                <div class="card">
                    <div class="card-body pb-1" v-if="!show_password_field">
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <p class="text-center">
                                    Enter your passphrase in the order in which it was assigned to you
                                </p>
                                <textarea v-model="passphrase" id="passphrase_re" cols="30" rows="5" class="form-control" placeholder="phrase1 phrase2 phrase3"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pb-1" v-else>
                        <p class="text-center">Update your password</p>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label">Password</label>
                                <input type="password" class="form-control" v-model="password" placeholder="********">
                            </div>
                        </div>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label">Retype Password</label>
                                <input type="password" class="form-control" v-model="re_password" placeholder="********">
                            </div>
                        </div>
                    </div>
                </div>


                <div class="form-links mt-2">
                    <div>
                        <router-link :to="{name: 'Auth'}">
                            <ion-icon name="chevron-back-outline"></ion-icon> Login
                        </router-link>
                    </div>
                </div>

                <div class="form-button-group transparent">
                    <button v-if="!show_password_field" @click.prevent="proceed" class="btn btn-primary btn-block btn-lg">Continue</button>
                    <button v-else @click.prevent="_proceed" class="btn btn-primary btn-block btn-lg">Login</button>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
import methodsMixin from '../../utils/methodsMixin';

export default {
    name: 'resetPassword',
    data(){
        return {
            passphrase: "",
            u_email: "",
            password: "",
            re_password: "",
            show_password_field: false
        }
    },
    mixins: [methodsMixin],
    methods: {
        async proceed(){
            // eslint-disable-next-line no-unused-vars
            const trimmed_phrase = this.passphrase.trim().split(' ');
            this.$loader.show();
            let response = await this.$store.dispatch('auth/getUserByPassphrase', trimmed_phrase);
            this.$loader.hide();
            if(response.status){
                this.u_email = response.data.data.email;
                this.show_password_field = true;
            }else{
                return this.$store.dispatch('notification/display_noti', {
                    message: response.message,
                })
            }
        },
        async _proceed(){
            if(!(this.password === this.re_password)){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Passwords do not match!',
                    timeout: 1500
                })
            }
            if(this.password.length <= 7){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Your password should be greater than 8 characters!',
                    timeout: 2000
                })
            }
            this.$loader.show();
            const {u_email, password} = this;
            let response = await this.$store.dispatch('auth/updatePassword', {email: u_email, password});
            if(response.status){
                response = await this.$store.dispatch('auth/_login', {email: u_email, password});
                if(response.status){
                    await this.$store.dispatch('notification/display_noti', {
                        message: 'Login Successful!',
                        timeout: 1500
                    });
                    setTimeout(()=>{
                        this.$router.replace({name: 'MetricsWallet'});
                    }, 2000);
                }else{
                    await this.$store.dispatch('notification/display_noti', {
                        message: 'Unable to complete login operation',
                        timeout: 2000
                    })
                }
            }else{
                await this.$store.dispatch('notification/display_noti', {
                    message: 'Unable to complete operation',
                    timeout: 2000
                })
            }
            return this.$loader.hide();
        }
    }
};
</script>

<style scoped>
.auth-logo{
    width: 100px;
    height: 100px
}
</style>